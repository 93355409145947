import React, { useState } from 'react'
import { Col, Container, Form, Nav, Navbar, Row, Spinner } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import ArenasochoaLogo from '../../img/logo.png'
import LogoMovil from '../../img/menu/logo.svg'
import Telefono from '../../img/telefono.png'
import Casa from '../../img/casa.png'
import Reloj from '../../img/reloj.png'
import useContactos from './useContactos';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import usePublicaciones from '../../pages/noticias/usePublicaciones';
import { Fab, MenuItem } from '@material-ui/core'
import { APP_URL } from '../../constants'
import MenuMovil from './MenuMovil'
import AddIcon from '@material-ui/icons/Add';
// import MovilMenu from './MovilMenu'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: '2px 4px',
//     display: 'flex',
//     alignItems: 'center',
//     width: 400,
//   },
//   input: {
//     marginLeft: theme.spacing(1),
//     flex: 1,
//   },
//   iconButton: {
//     padding: 10,
//     marginTop: 25
//   },
//   divider: {
//     height: 28,
//     margin: 4,
//   },
// }));

const useStyles = makeStyles((theme) => ({

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:'15px',
    cursor: 'pointer',
    color: '#C8C8C8',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    marginTop:'30px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
        cursor:'auto',
      },
    },
  },
  searchM: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  searchIconM: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    marginTop:'15px',
    cursor: 'pointer',
    color: '#C8C8C8',
    paddingLeft: `calc(1em + ${theme.spacing(12)}px)`,
  },
  inputRootM: {
    color: 'inherit',
    width: '100%'
  },
  inputInputM: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(16)}px)`,
    transition: theme.transitions.create('width'),
    width: '25%',
    marginTop:'30px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
        cursor:'auto',
      },
    },
  },
}));


const MenuEscritorio = props => {
  var dataPublicaciones = usePublicaciones();
  const classes = useStyles();
  // const history = useHistory()
  var contactoData = useContactos();
  // const clearInput = useRef(null)
  // console.log(history.location.pathname);
  const [showNosotros, setShowNosotros] = useState(false);
  const [showLineas, setShowLineas] = useState(false);
  const [busquedaActivada, setbusquedaActivada] = useState(false)
  const [busqueda, setBusqueda] = useState("")
  // const [filtroBusqueda, setFiltroBusqueda] = useState("")
  if (props === null || contactoData === null || dataPublicaciones === null) {
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }

  // hover drowdow menu
  // const showDropdown = e => {
  //   setShowNosotros(true);
  //   setShowLineas(false);
  // }
  const hideDropdown = e => {
    setShowNosotros(false);
    setShowLineas(false);
  }
  // const showDropdownLineas = e => {
  //   setShowNosotros(false);
  //   setShowLineas(true);
  // }

  const cerrarTodo = e =>{
    setbusquedaActivada(false)
    setBusqueda("")
  }

  let dataContact = contactoData.data[0]
  let publicaciones = dataPublicaciones.data
  // console.log(publicaciones);
  publicaciones=publicaciones.filter(item => item.nombre_publicacion.toLowerCase().includes(busqueda.toLowerCase()))
  // console.log(dataContact);
  return (
    <>
      <div className="menu-styles">
        <div>
          <div className="mt-3 ">
            <Container fluid className="d-none d-sm-block">
              <Row >
                <Col md={{ span: 2, offset: 6 }}>
                  <div className="mt-3 mr-5 pr-5">
                    <img className="float-left mt-2 " src={Telefono} alt="Telefono" />
                    <div className="texto-contacto ml-4 pl-2 mt-2">{dataContact.telefono_contacto}</div>
                    <div className="texto-contacto ml-4 pl-2 ">{dataContact.email_contacto}</div>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mt-3 mr-4">
                    <img className="float-left mt-2" src={Casa} alt="Casa" />
                    <div className="texto-contacto ml-4 pl-2 mt-2" dangerouslySetInnerHTML={{ __html: dataContact.direccion_contacto }} />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mt-3 ml-n5">
                    <img className="float-left mt-2" src={Reloj} alt="logo" />
                    <div className="texto-contacto ml-4 pl-2">{dataContact.horario_lu_vi_contacto}</div>
                    <div className="texto-contacto ml-4 pl-2">{dataContact.horario_sab_contacto}</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="mt-n4">
            <Navbar collapseOnSelect expand="lg" onMouseLeave={hideDropdown}>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
              <div className="mt-n3">
                <MenuMovil menuData={props.menuData} />
              </div>
              {/* movil */}
              <Navbar.Brand as={Link} to={'/inicio'} className="d-block d-sm-none mb-3">
                <img className="d-block d-sm-none py-3 pl-0" width="100px" src={LogoMovil} alt="logo" style={{marginLeft:"-40%"}} />
              </Navbar.Brand>
              
              <Navbar.Brand as={Link} to={'/inicio'} className="ml-xl-5">
                {/* escritorio */}
                <img className="ml-xl-5 d-none d-sm-block mt-n5" src={ArenasochoaLogo} alt="logo" />
                
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/inicio'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[0].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/perfilFirma'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[1].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/abogados'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[2].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/sectores'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[3].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/areasDePractia'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[4].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/noticias'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[5].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    activeClassName="linea"
                    className="ml-xl-5 mt-lg-5"
                    to={'/contacto'}
                    onMouseEnter={hideDropdown}
                  >
                    <div
                      className="menu-bold ml-xl-2 linea_hover"
                    >
                      {props.menuData[6].nombre_menu} <hr id="hr" className="mt-4" />
                    </div>
                  </Nav.Link>
                </Nav>

                <Nav className="mr-xl-5">
                  <Form inline>
                  <div className={classes.search} >
                    <div className={classes.searchIcon} >
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Buscar..."
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      type="search"
                      value={busqueda}
                      onChange={e => {
                        setBusqueda(e.target.value)
                        if (e.target.value === "") {
                          // setFiltroBusqueda("")
                        }
                        e.target.value === "" ? setbusquedaActivada(false) : setbusquedaActivada(true)
                      }}
                    />
                  </div>
                      <div className={busquedaActivada === true ? ('modal_busqueda') : ('d-none') }>
                        <div style={{cursor:"pointer", position:"absolute", top: "-20px", right: "-10px", color:"red" }} onClick={()=>cerrarTodo()}><b>X</b></div>
                        <span className="resultado_titulo"> Resultados de la búsqueda:</span> 
                          <hr className="hr_b"/>
                          <div style={{
                            borderRadius:"50%",
                            border:"3px solid #1C2D56",
                            width:"2px",
                            marginTop:"-20px",
                            marginLeft: "50%"
                          }}></div>
                          {publicaciones.length  ?

                          publicaciones
                          .slice(0, 5)
                          .map(publicacion => (
                            <div key={publicacion.id} className="p-2">
                              <MenuItem>
                               <a href={APP_URL+'noticia/'+publicacion.nombre_publicacion}>{publicacion.nombre_publicacion}</a>
                              </MenuItem>                              
                            </div>
                          ))
                          :
                          "no hay resultados para tu búsqueda..."}
                      </div>
                  </Form>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <div>
                <Form inline className="d-block d-sm-none" style={{position:"absolute", top:"0px", right:"0px", width:"60%"}}>
                  <div className={classes.searchM} >
                    <div className={classes.searchIconM} >
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Buscar.."
                      classes={{
                        root: classes.inputRootM,
                        input: classes.inputInputM,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      type="search"
                      value={busqueda}
                      onChange={e => {
                        setBusqueda(e.target.value)
                        if (e.target.value === "") {
                          // setFiltroBusqueda("")
                        }
                        e.target.value === "" ? setbusquedaActivada(false) : setbusquedaActivada(true)
                      }}
                    />
                  </div>
                      <div className={busquedaActivada === true ? ('modal_busqueda') : ('d-none') }>
                        <div style={{cursor:"pointer", position:"absolute", top: "-20px", right: "-10px", color:"red" }} onClick={()=>cerrarTodo()}><b>X</b></div>
                        <span className="resultado_titulo"> Resultados de la búsqueda:</span> 
                          <hr className="hr_b"/>
                          <div style={{
                            borderRadius:"50%",
                            border:"3px solid #1C2D56",
                            width:"2px",
                            marginTop:"-20px",
                            marginLeft: "50%"
                          }}></div>
                          {publicaciones.length  ?

                          publicaciones
                          .slice(0, 5)
                          .map(publicacion => (
                            <div key={publicacion.id} className="p-2">
                              <MenuItem>
                               <a href={APP_URL+'noticia/'+publicacion.nombre_publicacion}>{publicacion.nombre_publicacion}</a>
                              </MenuItem>                              
                            </div>
                          ))
                          :
                          "no hay resultados para tu búsqueda..."}
                      </div>
                  </Form>
            </div>
                  
                  {/* <Fab color="" aria-label="add" className="d-block d-sm-none" style={{position:"fixed"}}>
                    <MenuMovil menuData={props.menuData} />
                  </Fab> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuEscritorio;