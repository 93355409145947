import React, { useRef, useState } from 'react';
import Footer from '../../components/footer/Footer';
import CarruselClientes from '../../components/carrusel_clientes/CarruselClientes'
import useClientes from './useClientes';
import useCarrucelInicio from './useCarrucelInicio';
import useInicio from './useInicio';
import usePublicaciones from '../noticias/usePublicaciones';
import usePageContactos from '../contacto/usePageContactos';
import useAbogados from '../abogados/useAbogados';
import { Form, Col, Container, Row, Spinner } from 'react-bootstrap';
import {Button as Buttons} from 'react-bootstrap';
import CaruselInicio from '../../components/carusel_inicio/CaruselInicio';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API_URL, API_URL_MAIL } from '../../constants';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es-mx';
import { Link as Links } from 'react-router-dom';
import ReactPlayer from 'react-player'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function Inicio (props) {
  const classes = useStyles();
  var dataClientes = useClientes();
  var datosCarrucelInicio = useCarrucelInicio();
  var datosInicio = useInicio();
  var dataPublicaciones = usePublicaciones();
  var dataPageContactos = usePageContactos();
  var dataAbogados = useAbogados();

  const [name, setName] = useState("")
  const [apellido, setApellido] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [authorized, setAuthorized] = useState(false)
  const [sending, setSending] = useState(false)
  const formRef = useRef(null)
  if (dataClientes === null || datosCarrucelInicio === null || datosInicio === null || dataPublicaciones === null || dataPageContactos === null || dataAbogados === null) {
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }
   let datos = dataClientes.data
   let datosCarrucel = datosCarrucelInicio.data
   let datosIni = datosInicio.data[0]
   let publicaciones = dataPublicaciones.data
   let data = dataPageContactos.data[0]
   let dataA = dataAbogados.data
  //  console.log(dataA);

  //  console.log(datosIni);

   
  let test = {
    name: name,
    apellido: apellido,
    phone: phone,
    email: email,
    mensaje: mensaje,
    authorized: authorized,
    metodo: "contactForm",
  }
  // console.log(test)


  async function submit(e) {
    e.preventDefault()
    setSending(true)

    const rawData = {
      name: name,
      apellido: apellido,
      phone: phone,
      email: email,
      mensaje: mensaje,
      authorized: authorized,
      metodo: "contactForm",
    }

    try {
      const response = await axios.post(API_URL_MAIL+'enviar-mail.php', rawData)
      setSending(false)
      setName("")
      setApellido("")
      setPhone("")
      setEmail("")
      setMensaje("")
      setAuthorized(false)
      formRef.current.reset()
      alert("Tus datos han sido envidos correctamente, un asesor te contactará a la brevedad")
    } catch (error) {
      const err = Object.assign({}, error)
      console.log(error)
      console.log(err)
    }
  }

  return (
    <>
      {props.menu} 
      <div className="d-block d-sm-none" style={{marginTop:"-24px"}} >
      </div>
      <div className="pb-5 mb-4" style={{marginTop:"-24px"}}>
          <CaruselInicio data={datosCarrucel}/>
      </div>
      <div style={{minHeight:"690px"}} className="inicio-style">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={{span:10, offset:1}} md={9}>
              {/* escritorio */}
              <div className="titulo_inicio text-center pb-4 d-none d-sm-block">
                {datosIni.titulo_inicio}
              </div>
              {/* movil */}
              <div className="titulo_inicio_movil text-center pb-2 d-block d-sm-none">
                {datosIni.titulo_inicio}
              </div>
            </Col>
            <Col md={7}>
              {/* escritorio */}
              <div className="sub_titulo_inicio pb-5 d-none d-sm-block">
                {datosIni.sub_titulo_inicio}
              </div>
              {/* movil */}
              <div className="sub_titulo_inicio pb-5 mb-4 d-block d-sm-none">
                {datosIni.sub_titulo_inicio}
              </div>
            </Col>
            {/* escritorio */}
            <Col md={9} className="mb-5 pb-5 d-none d-sm-block">
              <div className="parrafo_inicio" dangerouslySetInnerHTML={{ __html: datosIni.parrafo }} />
            </Col>
            {/* movil */}
            <Col md={9} className="mb-4 pb-2 d-block d-sm-none">
              <div className="parrafo_inicio" dangerouslySetInnerHTML={{ __html: datosIni.parrafo }} />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-md-center ">
            <Col md={4}>
              <div className="ultimos_videos text-center">
                {datosIni.titulo_videos_inicio}
                <hr className="hr_n"/>
                <div style={{
                    borderRadius:"50%",
                    border:"3px solid #F58465",
                    width:"2px",
                    marginTop:"-20px",
                    marginLeft: "50%"
                  }}></div>
              </div>
            </Col>
          </Row>
        </Container>
        
        {/* escritorio */}
        <Container fluid className="d-none d-sm-block">
          <Row className="justify-content-md-center pt-5 pb-5">
            <Col md={{span: 9, offset: 1}}>
              <Row>
                {publicaciones
                .sort(({ id: previousID }, { id: currentID }) =>  currentID - previousID)
                .filter(item => Number(item.categoria_id) === Number(13))
                .slice(0, 3)
                .map(publicacion => (
                  <div key={publicacion.id} className="col-md-4 pb-5">
                    <Links to={`/noticia/${publicacion.nombre_publicacion}`} style={{textDecoration:"none"}}>
                      <Card className={classes.root} >
                        <CardActionArea>
                          {publicacion.categoria_nombre === "Videos" ?
                            <ReactPlayer
                              height="295px" 
                              width="100%"
                              controls
                              url={publicacion.imagen_publicacion.startsWith('http') ? publicacion.imagen_publicacion : API_URL+publicacion.imagen_publicacion} 
                            />
                          :
                            <CardMedia
                              className={classes.media}
                              image={API_URL+publicacion.imagen_publicacion}
                              title={publicacion.nombre_publicacion}
                              style={{height: "300px"}}
                            />
                          }
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className="titulo_noticia text-truncate">
                              {publicacion.nombre_publicacion}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className="fecha ">
                              {moment(publicacion.fecha_publicacion).format('DD [de] MMMM [de] YYYY')} - {publicacion.categoria_nombre}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className="parrafo_noticia">
                              <div dangerouslySetInnerHTML={{ __html: publicacion.parrafo.substr(0,120)+"..." }}/>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button size="small" color="primary" >
                            <div className="nombre_vermas" style={{textTransform: "none", color:"#F58465"}}>Ver más</div>
                          </Button>
                        </CardActions>
                      </Card>
                    </Links> 
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
        {/* movil */}
        <Container className="d-block d-sm-none pt-4 mb-5 pb-2">
          <Row>
            {publicaciones
            .sort(({ id: previousID }, { id: currentID }) =>  currentID - previousID)
            .filter(item => Number(item.categoria_id) === Number(13))
            .slice(0, 4)
            .map(publicacion => (
              <Col xs={6} key={publicacion.id} className="pb-5">
                <Links to={`/noticia/${publicacion.nombre_publicacion}`} style={{textDecoration:"none"}}>
                  <Card className={classes.root} >
                    <CardActionArea>
                      {publicacion.categoria_nombre === "Videos" ?
                        <ReactPlayer
                          height="150px"
                          width="150px"
                          controls
                          url={publicacion.imagen_publicacion.startsWith('http') ? publicacion.imagen_publicacion : API_URL+publicacion.imagen_publicacion} 
                        />
                      :
                        <CardMedia
                          className={classes.media}
                          image={API_URL+publicacion.imagen_publicacion}
                          title={publicacion.nombre_publicacion}
                        />
                      }
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className="titulo_noticia text-truncate">
                          {publicacion.nombre_publicacion}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Links> 
              </Col>
            ))}
          </Row>
        </Container>

        <Container fluid>
          <Row className="justify-content-md-center" style={{
            // minHeight:"750px", 
            backgroundColor:"#1C2D56",}}>
            <Col md={{span:4, offset:0}} className="pt-5 mt-5" >
              <div className="titulo_derecho_especializado">{datosIni.titulo_derecho_especializado}</div>
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
            </Col>
            <Col md={12} className="justify-content-md-center text-white text-center pt-5 pb-5">
              <Container>
                <Row className="pb-5 mt-0">
                  <Col xs={6} md={3} className="pb-5">
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_01} alt="img_01"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_01} width="76px" alt="img_01"/></div>
                    <div className="pt-2">{datosIni.nombre_img_01}</div>
                  </Col>
                  <Col xs={6} md={3} className="pb-5">
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_02} alt="img_02"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_02} width="76px" alt="img_02"/></div>
                    <div className="pt-2">{datosIni.nombre_img_02}</div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_03} alt="img_03"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_03} width="76px" alt="img_03"/></div>
                    <div className="pt-2">{datosIni.nombre_img_03}</div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_04} alt="img_04"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_04} width="76px" alt="img_04"/></div>
                    <div className="pt-2">{datosIni.nombre_img_04}</div>
                  </Col>
                </Row>
                <Row className="pb-5" >
                  <Col xs={6} md={3} className="pb-5">
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_05} alt="img_05"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_05} width="76px" alt="img_05"/></div>
                    <div className="pt-2">{datosIni.nombre_img_05}</div>
                  </Col>
                  <Col xs={6} md={3} className="pb-5">
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_06} alt="img_06"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_06} width="76px" alt="img_06"/></div>
                    <div className="pt-2">{datosIni.nombre_img_06}</div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_07} alt="img_07"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_07} width="76px" alt="img_07"/></div>
                    <div className="pt-2">{datosIni.nombre_img_07}</div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="d-none d-sm-block" ><img src={API_URL+datosIni.img_08} alt="img_08"/></div>
                    <div className="d-block d-sm-none" ><img src={API_URL+datosIni.img_08} width="76px" alt="img_08"/></div>
                    <div className="pt-2">{datosIni.nombre_img_08}</div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-md-center pt-4 mt-5">
            <Col xs={{span:10, offset:1}} md={{span:6, offset:0}}>
              {/* escritorio */}
              <div className="titulo_años_experiencia text-center pb-2 d-none d-sm-block">
                {datosIni.titulo_años_exp}
              </div>
              {/* movil */}
              <div className="titulo_inicio_movil text-center pb-2 d-block d-sm-none">
                {datosIni.titulo_años_exp}
              </div>
              
              <div className="sub_titulo_años_exp pb-5 mb-4">
                {datosIni.sub_titulo_años_exp}
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row style={{zIndex:"100"}}>
            <Col style={{maxHeight:"400px"}}>
              <div className="col-md-8 offset-md-2 mt-md-5 p-5" 
                style={{
                  background:"white", 
                  borderRadius:"4px", 
                  boxShadow: "6px 6px 60px #00000012", 
                  // padding:"100px", 
                  marginTop:"0px", 
                  marginBottom:"100px"
                }}>
                <Form onSubmit={e => submit(e)} ref={formRef}>
                  <div className="titulo_formulario col-md-6 offset-md-3">{data.titulo_contacto}
                    <hr className="hr_n"/>
                    <div style={{
                      borderRadius:"50%",
                      border:"3px solid #F58465",
                      width:"2px",
                      marginTop:"-20px",
                      marginLeft: "50%"
                    }}></div>
                  </div>
                    <div className="sub_titulo_contacto pt-4 mt-2">{data.sub_titulo_contacto}</div>
                    <div className="detalle_sub_titulo col-md-10 offset-md-1 pb-4">{data.detalle_sub_titulo_contacto}</div>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Row>
                      <Col md={6}>
                        <Form.Label> </Form.Label>
                        <Form.Control type="text" placeholder="Nombres*" 
                        value={name} 
                        onChange={e => setName(e.target.value)}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Label> </Form.Label>
                        <Form.Control type="text" placeholder="Apellidos*" 
                        value={apellido} 
                        onChange={e => setApellido(e.target.value)}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Label> </Form.Label>
                        <Form.Control type="email" placeholder="Correo electrónico*" 
                        value={email} 
                        onChange={e => setEmail(e.target.value)}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Label> </Form.Label>
                        <Form.Control type="text" placeholder="Teléfono de contacto*" 
                        value={phone} 
                        onChange={e => setPhone(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label> </Form.Label>
                      <Form.Control as="textarea" rows={5} placeholder="Detalle del caso" style={{ resize: "none" }} 
                      value={mensaje} 
                      onChange={e => setMensaje(e.target.value)}
                      />
                    </Form.Group>
                  
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <label htmlFor="default-checkboxx" className="terminos_condiciones">
                    <input type="checkbox" className="mr-2" id="default-checkboxx" 
                    checked={authorized} 
                    onChange={e => setAuthorized(e.target.checked)}
                    />
                      <span className="terminos">Usted acepta los <a href={API_URL+"uploads/terminos/terminos_y_condiciones.pdf"} target="_blank" rel="noopener noreferrer" className="">términos y condiciones</a> establecidos por este sitio.</span> 
                    </label>
                  </Form.Group>
                  <Col md={{span:6, offset:3}}>
                    <Form.Group controlId="exampleForm.ControlInput1" className="text-center pt-3">
                      {sending ? (
                        <div className="spinner-border" role="status" style={{ color: "#1C2D56" }}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                      <Buttons 
                        type="submit"
                        disabled={name === "" || apellido === "" || email === "" || !authorized || mensaje=== ""}
                        style={{
                          paddingLeft:"28px",
                          paddingRight:"28px",
                          color:"white",
                          paddingTop:"5px",
                          paddingBottom:"5px",
                          borderRadius: "calc(0.25rem - 1px)",
                          fontWeight: "bold",
                          width:"100%",
                        }}>Enviar Valoración
                      </Buttons>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <div className="terminos_condiciones">*Campos obligatorios.</div> */}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid style={{ zIndex:"-1000"}}>
          <Row style={{minHeight:"500px", backgroundColor:"#F58465", zIndex:"-1000"}}>
            <Col className="col-12" style={{minHeight:"450px", zIndex:"-1000"}}>
            </Col>
            {/* movil */}
            <Col className="col-12 d-block d-sm-none" style={{minHeight:"150px", zIndex:"-1000"}}>
            </Col>
            <Col>
              {/* escritorio */}
              <div className="titulo_nuestros_abogados col-md-4 offset-md-4 pb-5 d-none d-sm-block">
                {datosIni.titulo_nuestros_abogados}
                <hr className="hr_b w-75"/>
                    <div style={{
                      borderRadius:"50%",
                      border:"3px solid #1C2D56",
                      width:"2px",
                      marginTop:"-20px",
                      marginLeft: "50%"
                    }}></div>
              </div>
              {/* movil */}
              <div className="titulo_nuestros_abogados pb-2 d-block d-sm-none">
                {datosIni.titulo_nuestros_abogados}
                <hr className="hr_b "/>
                    <div style={{
                      borderRadius:"50%",
                      border:"3px solid #1C2D56",
                      width:"2px",
                      marginTop:"-20px",
                      marginLeft: "50%"
                    }}></div>
              </div>
              {/* escritorio */}
              <div className="sub_titulo_nuestros_abogados col-6 offset-3 pb-5 mb-5 d-none d-sm-block">
                {datosIni.sub_titulo_nuestros_abogados}
              </div> 
              {/* movil */}
              <div className="sub_titulo_nuestros_abogados pb-2 mb-5 d-block d-sm-none">
                {datosIni.sub_titulo_nuestros_abogados}
              </div>  
            </Col>
            <Col className="col-12 text-center">
              <Container>
                <Row className="pb-5 mt-0">
                  <Col xs={6} md={3} style={{textAlign: "-webkit-center"}} className="pb-5 pb-md-0"> 
                    {/* escritorio */}
                    <img
                      width={164}
                      height={164}
                      className="mb-4 d-none d-sm-block"
                      src={API_URL+dataA[2].imagen}
                      alt="dataA[2].imagen"
                    /> 
                    {/* movil */}
                    <img
                      width={107}
                      height={107}
                      className=" mb-1 d-block d-sm-none"
                      src={API_URL+dataA[2].imagen}
                      alt="dataA[2].imagen"
                    />
                    {/* escritorio */}
                    <div className="titulo_nombre_asociados d-none d-sm-block">{dataA[2].nombre}</div>
                    {/* movil */}
                    <div className="titulo_nombre_asociados_movil d-block d-sm-none">{dataA[2].nombre}</div>
                    <div className="sub_titulo_nombre_asociados pb-3">{dataA[2].cargo}</div>
                    <hr className="hr_b d-none d-sm-block"/>
                    <div style={{
                      width:'250px'
                    }} className="parrafo_abogado_asociados d-none d-sm-block">
                      {dataA[2].parrafo} 
                    </div>
                  </Col>
                  <Col xs={6} md={3} style={{textAlign: "-webkit-center"}} className="pb-5 pb-md-0">
                    {/* escritorio */}
                    <img
                      width={164}
                      height={164}
                      className="mb-4 d-none d-sm-block"
                      src={API_URL+dataA[3].imagen}
                      alt="dataA[3].imagen"
                    />
                    {/* movil */}
                    <img
                      width={107}
                      height={107}
                      className=" mb-1 d-block d-sm-none"
                      src={API_URL+dataA[3].imagen}
                      alt="dataA[3].imagen"
                    />
                    {/* escritorio */}
                    <div className="titulo_nombre_asociados d-none d-sm-block">{dataA[3].nombre}</div>
                    {/* movil */}
                    <div className="titulo_nombre_asociados_movil d-block d-sm-none">{dataA[3].nombre}</div>
                    <div className="sub_titulo_nombre_asociados pb-3">{dataA[3].cargo}</div>
                    <hr className="hr_b d-none d-sm-block"/>
                    <div style={{
                      width:'250px'
                    }} className="parrafo_abogado_asociados d-none d-sm-block">
                      {dataA[3].parrafo} 
                    </div>
                  </Col>
                  <Col xs={6} md={3} style={{textAlign: "-webkit-center"}}>
                    {/* escritorio */}
                    <img
                      width={164}
                      height={164}
                      className="mb-4 d-none d-sm-block"
                      src={API_URL+dataA[7].imagen}
                      alt="dataA[7].imagen"
                    />
                    {/* movil */}
                    <img
                      width={107}
                      height={107}
                      className=" mb-1 d-block d-sm-none"
                      src={API_URL+dataA[7].imagen}
                      alt="dataA[7].imagen"
                    />
                    {/* escritorio */}
                    <div className="titulo_nombre_asociados d-none d-sm-block">{dataA[7].nombre}</div>
                    {/* movil */}
                    <div className="titulo_nombre_asociados_movil d-block d-sm-none">{dataA[7].nombre}</div>
                    <div className="sub_titulo_nombre_asociados pb-3">{dataA[7].cargo}</div>
                    <hr className="hr_b d-none d-sm-block"/>
                    <div style={{
                      width:'250px'
                    }} className="parrafo_abogado_asociados d-none d-sm-block">
                      {dataA[7].parrafo} 
                    </div>
                  </Col>
                  <Col xs={6} md={3} style={{textAlign: "-webkit-center"}}>
                    {/* escritorio */}
                    <img
                      width={164}
                      height={164}
                      className="mb-4 d-none d-sm-block"
                      src={API_URL+dataA[4].imagen}
                      alt="dataA[4].imagen"
                    />
                    {/* movil */}
                    <img
                      width={107}
                      height={107}
                      className=" mb-1 d-block d-sm-none"
                      src={API_URL+dataA[4].imagen}
                      alt="dataA[4].imagen"
                    />
                    {/* escritorio */}
                    <div className="titulo_nombre_asociados d-none d-sm-block">{dataA[4].nombre}</div>
                    {/* movil */}
                    <div className="titulo_nombre_asociados_movil d-block d-sm-none">{dataA[4].nombre}</div>
                    <div className="sub_titulo_nombre_asociados pb-3">{dataA[4].cargo}</div>
                    <hr className="hr_b d-none d-sm-block"/>
                    <div style={{
                      width:'250px'
                    }} className="parrafo_abogado_asociados d-none d-sm-block">
                      {dataA[4].parrafo} 
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col> 
            {/* escritorio */}
            <Col className="text-center mb-5 pb-5 d-none d-sm-block">
              <Buttons 
                as={Links}
                to={'/abogados'}
                style={{
                  paddingLeft:"28px",
                  paddingRight:"28px",
                  color:"white",
                  paddingTop:"5px",
                  paddingBottom:"5px",
                  borderRadius: "calc(0.25rem - 1px)",
                  fontWeight: "bold",
                  width:"10%",
              }}>
                Ver Todos
              </Buttons>
            </Col> 
            {/* movil */}
            <Col className="text-center mb-5 pb-5 d-block d-sm-none">
              <Buttons 
                as={Links}
                to={'/abogados'}
                style={{
                  paddingLeft:"28px",
                  paddingRight:"28px",
                  color:"white",
                  paddingTop:"5px",
                  paddingBottom:"5px",
                  borderRadius: "calc(0.25rem - 1px)",
                  fontWeight: "bold",
                  width:"50%",
              }}>
                Ver Todos
              </Buttons>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-md-center pt-5 mt-5 mb-5">
            <Col md={3} className="cliente">
              CLIENTES
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
            </Col>
          </Row>
        </Container>
        <div className="mb-5 pb-md-1">
          <CarruselClientes data ={datos}/>
        </div>

        <Container>
          <Row className="justify-content-md-center pt-5 mt-5 mb-5">
            <Col md={3} className="cliente">
              {datosIni.titulo_probono}
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
            </Col>
            <Col md={10} className="sub_titulo_probono pt-3 pb-5 mb-5">
                {datosIni.sub_titulo_probono}
            </Col>
            <Col xs={{span:10, offset:1}} md={{span:3, offset:0}} className="text-center">
              <img src={API_URL+datosIni.logo_assisto} alt="logo_assisto"/>
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
                <Buttons className="mt-4 mb-5"
                  href={datosIni.url_assisto}
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{
                    paddingLeft:"28px",
                    paddingRight:"28px",
                    color:"white",
                    paddingTop:"5px",
                    paddingBottom:"5px",
                    borderRadius: "calc(0.25rem - 1px)",
                    fontWeight: "bold",
                    width:"100%",
                  }}
                >
                  Conéctate con assisto
                </Buttons>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </>
  );
}

export default Inicio;