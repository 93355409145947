import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAreas } from '../../redux/actions/getAreas';

export default function useAreas (props) {

    const areas = useSelector(state => state.areas);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAreas())
    },[])

    return areas;
}