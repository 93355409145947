import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getPublicacion } from '../../redux/actions/getPublicacion';

export default function usePublicacion (props) {

    const publicacion = useSelector(state => state.publicacion);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPublicacion(props))
    },[])

    return publicacion;
}