import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import usePageContactos from './usePageContactos';
import axios from 'axios';
import { API_URL, API_URL_MAIL } from '../../constants';

function Contacto (props) {

  var dataPageContactos = usePageContactos();

  const [name, setName] = useState("")
  const [apellido, setApellido] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [authorized, setAuthorized] = useState(false)
  const [sending, setSending] = useState(false)
  const formRef = useRef(null)

  if(dataPageContactos === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }

  let data = dataPageContactos.data[0]
  // console.log(data);


  let test = {
    name: name,
    apellido: apellido,
    phone: phone,
    email: email,
    mensaje: mensaje,
    authorized: authorized,
    metodo: "contactForm",
  }
  // console.log(test)


  async function submit(e) {
    e.preventDefault()
    setSending(true)

    const rawData = {
      name: name,
      apellido: apellido,
      phone: phone,
      email: email,
      mensaje: mensaje,
      authorized: authorized,
      metodo: "contactForm",
    }

    try {
      const response = await axios.post(API_URL_MAIL+'enviar-mail.php', rawData)
      setSending(false)
      setName("")
      setApellido("")
      setPhone("")
      setEmail("")
      setMensaje("")
      setAuthorized(false)
      formRef.current.reset()
      alert("Tus datos han sido envidos correctamente, un asesor te contactará a la brevedad")
    } catch (error) {
      const err = Object.assign({}, error)
      console.log(error)
      console.log(err)
    }
  }
  
  return (
    <>
      {props.menu} 
        <div className="contacto-style">
          <div style={{minHeight:"650px", marginTop: "-16px" }} className="d-none d-sm-block">
            <iframe 
              title="mapaGmail"
              width="100%" 
              height="650px" 
              src={data.url_map}
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>
          <div style={{minHeight:"350px", marginTop: "-16px" }} className="d-block d-sm-none">
            <iframe 
              title="mapaGmail"
              width="100%" 
              height="350px" 
              src={data.url_map}
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>
          <div>

            <div className="col-10 offset-1 col-md-6 offset-md-3 p-md-5 clasecontactanos" style={{
              background:"white", 
              borderRadius:"4px", 
              boxShadow: "6px 6px 60px #00000012",  
              marginBottom:"100px"}}>
              <Form 
              onSubmit={e => submit(e)} ref={formRef}
              >
                <div className="titulo_formulario col-md-6 offset-md-3">{data.titulo_contacto}
                  <hr className="hr_n"/>
                  <div style={{
                    borderRadius:"50%",
                    border:"3px solid #F58465",
                    width:"2px",
                    marginTop:"-20px",
                    marginLeft: "50%"
                  }}></div>
                </div>
                  <div className="sub_titulo_contacto pt-4 mt-2">{data.sub_titulo_contacto}</div>
                  <div className="detalle_sub_titulo pb-4">{data.detalle_sub_titulo_contacto}</div>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Row>
                    <Col md={6}>
                      <Form.Label> </Form.Label>
                      <Form.Control type="text" placeholder="Nombres*" 
                      value={name} 
                      onChange={e => setName(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label> </Form.Label>
                      <Form.Control type="text" placeholder="Apellidos*" 
                      value={apellido} 
                      onChange={e => setApellido(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label> </Form.Label>
                      <Form.Control type="email" placeholder="Correo electrónico*" 
                      value={email} 
                      onChange={e => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label> </Form.Label>
                      <Form.Control type="text" placeholder="Teléfono de contacto*" 
                      value={phone} 
                      onChange={e => setPhone(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label> </Form.Label>
                    <Form.Control as="textarea" rows={5} placeholder="Detalle del caso" style={{ resize: "none" }} 
                    value={mensaje} 
                    onChange={e => setMensaje(e.target.value)}
                    />
                  </Form.Group>
                
                <Form.Group controlId="exampleForm.ControlInput1">
                  <label htmlFor="default-checkboxx" className="terminos_condiciones">
                  <input type="checkbox" className="mr-2" id="default-checkboxx" 
                  checked={authorized} 
                  onChange={e => setAuthorized(e.target.checked)}
                  />
                    <span className="terminos">Usted acepta los <a href={API_URL+"uploads/terminos/terminos_y_condiciones.pdf"} target="_blank" rel="noopener noreferrer" className="">términos y condiciones</a> establecidos por este sitio.</span> 
                  </label>
                </Form.Group>
                <Col md={{span:6, offset:3}}>
                  <Form.Group controlId="exampleForm.ControlInput1" className="text-center pt-3">
                    {sending ? (
                      <div className="spinner-border" role="status" style={{ color: "#1C2D56" }}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                    <Button 
                      type="submit"
                      disabled={name === "" || apellido === "" || email === "" || !authorized || mensaje=== ""}
                      style={{
                        paddingLeft:"28px",
                        paddingRight:"28px",
                        color:"white",
                        paddingTop:"5px",
                        paddingBottom:"5px",
                        borderRadius: "calc(0.25rem - 1px)",
                        fontWeight: "bold",
                        width:"100%",
                      }}>Enviar Valoración
                    </Button>
                    )}
                  </Form.Group>
                </Col>
                {/* <div className="terminos_condiciones">*Campos obligatorios.</div> */}
              </Form>
            </div>


          </div>
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-12 col-md-4 offset-md-1 pb-5 pb-md-0">
                <div className="titulos text-center text-md-left">{data.ciudad01}</div>  
                <div className="parrafos text-center text-md-left">{data.dir_ciudad01_1}</div>
                <div className="parrafos text-center text-md-left">{data.dir_ciudad01_2} </div>
              </div>
              <div className="col-12 col-md-4 pb-5 pb-md-0">
                <div className="titulos text-center text-md-left">{data.ciudad02}</div>  
                <div className="parrafos text-center text-md-left">{data.dir_ciudad02_1}</div>
                <div className="parrafos text-center text-md-left">{data.dir_ciudad02_2}</div>
              </div>
              <div className="col-12 col-md-3">
                <div className="titulos text-center text-md-left">{data.ciudad03}</div> 
                <div className="parrafos text-center text-md-left">{data.dir_ciudad03_1}</div> 
              </div>
            </div>
          </div>
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-12 col-md-4 offset-md-1 pb-5 pb-md-0">
                <div className="titulos text-center text-md-left">{data.comunicacion_01}</div> 
                <div className="parrafos text-center text-md-left">{data.comunicacion_02}</div>
                <div className="parrafos text-center text-md-left">{data.comunicacion_03}</div>
                <div className="parrafos text-center text-md-left">{data.comunicacion_04}</div>
              </div>
              <div className="col-12 col-md-4 pb-5 pb-md-0">
                <div className="titulos text-center text-md-left">{data.horarios_01}</div> 
                <div className="parrafos text-center text-md-left">{data.horarios_02}</div>
                <div className="parrafos text-center text-md-left">{data.horarios_03}</div> 
              </div>
              <div className="col-12 col-md-3">
                <div className="titulos text-center text-md-left">{data.trabaja_con_nosotros_01}</div> 
                <div className="parrafos text-center text-md-left">{data.trabaja_con_nosotros_02}</div> 
              </div>
            </div>
          </div>
        </div>
      <Footer/>
    </>
  );
}

export default Contacto;