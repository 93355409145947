import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { API_URL } from '../../constants';
import useFooters from './useFooters';
import useMenus from '../../useMenus';
import useContactos from '../menu/useContactos';
import Coordenada from '../../img/coordenada.png'
import Telefono_naranjado from '../../img/telefono_naranjado.png'
import Mail_naranjado from '../../img/mail_naranjado.png'
import Reloj_naranjado from '../../img/reloj_naranjado.png'
import { Link } from 'react-router-dom';


function Footer() {
  var dataFooter = useFooters();
  var menuAxio = useMenus();
  var contactoData = useContactos();

  if (dataFooter === null || menuAxio === null || contactoData === null) {
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }

  let datos = dataFooter.data[0]
  let datosMenu = menuAxio.data
  let datosContacto = contactoData.data[0]
  // console.log(contactoData);
  // console.log(datosMenu);
  let caracter = /<br>/g;
  
  return (
    <>
    <div className="footer-style">
      {/* escritorio */}
      <div className="boddy-footer d-none d-sm-block">
        <Container className="pt-5" fluid>
          <Row className="justify-content-md-center">
            <Col xs lg="2" className="mt-5 pt-3 text-right">
              <img className="mt-n3 pr-4" src={API_URL+datos.img_logo} alt="img_logo"/>
            </Col>
            <Col xs lg="4">
              <hr className="hr_footer float-left d-none d-sm-block"/>
              <div className="m-4 pl-4 pt-4 parrafo_footer d-none d-sm-block">{datos.parrafo_footer}</div>
            </Col>
            <Col xs lg="4" className="d-none d-sm-block">
              <hr className="hr_footer float-left d-none d-sm-block"/>
              <div className="row parrafo_footer pl-4 ml-2">
                <div className="col-12 pt-4 titulo_footer">{datos.titulo_menu}</div>
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/inicio'}><span className="menu_footer">{datosMenu[0].nombre_menu}</span></Link></div>
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/sectores'}><span className="menu_footer">{datosMenu[3].nombre_menu}</span></Link></div>
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/noticias'}><span className="menu_footer">{datosMenu[5].nombre_menu}</span></Link></div>
              </div>
              <div className="row parrafo_footer pl-4 ml-2">
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/perfilFirma'}><span className="menu_footer">{datosMenu[1].nombre_menu}</span></Link></div>
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/areasDePractia'}><span className="menu_footer">{datosMenu[4].nombre_menu}</span></Link></div>
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/contacto'}><span className="menu_footer">{datosMenu[6].nombre_menu}</span></Link></div>
              </div>
              <div className="row parrafo_footer pl-4 ml-2">
                <div className="col-4 pt-2"><span className="mayor_que">&gt;</span> <Link to={'/abogados'}><span className="menu_footer">{datosMenu[2].nombre_menu}</span></Link></div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="pt-5 mt-4" fluid>
          <Row className="justify-content-md-center">
            <Col xs lg="12"><div className="text-center parrafo_footer">
              <img src={Coordenada} alt="Coordenada" className="pl-4 pr-2"/>{datosContacto.direccion_contacto.replace(caracter, '')}
              <img src={Telefono_naranjado} alt="Telefono_naranjado" className="pl-4 pr-2"/><a href={"tel:"+datosContacto.telefono_contacto} className="text-white">{datosContacto.telefono_contacto}</a>
              <img src={Mail_naranjado} alt="Mail_naranjado" className="pl-4 pr-2"/><a href={"mailto:"+datosContacto.email_contacto} className="text-white">{datosContacto.email_contacto}</a>
              <img src={Reloj_naranjado} alt="Reloj_naranjado" className="pl-4 pr-2"/>{datosContacto.horario_lu_vi_contacto}
              </div></Col>
          </Row>
        </Container>
        <div className="text-center copyright_footer pt-4">{datos.copyright}</div>
        <div className="text-center"><a href="https://pvsagencia.com/" target="_blank" rel="noopener noreferrer" className=" copyright_footer pt-2">Diseñado y desarrollado por PVS AGENCIA</a> </div>
      </div>

      {/* movil */}
      <div className="boddy-footer d-block d-sm-none">
        <Container className="pt-3" fluid>
          <Row className="justify-content-md-center">
            <Col>
              <div className="text-center parrafo_footer">
                <div>
                  <img className="pt-5 pb-3" src={API_URL+datos.img_logo} alt="img_logo"/>
                </div>
                <div>
                  <img src={Coordenada} alt="Coordenada" className="pr-2"/>{datosContacto.direccion_contacto.replace(caracter, '')}
                </div>
                <div>
                  <img src={Telefono_naranjado} alt="Telefono_naranjado" className="pr-2"/>
                  <a href={"tel:"+datosContacto.telefono_contacto} className="text-white">{datosContacto.telefono_contacto}</a>
                </div>
                <div>
                  <img src={Mail_naranjado} alt="Mail_naranjado" className="pr-2"/>
                  <a href={"mailto:"+datosContacto.email_contacto} className="text-white">{datosContacto.email_contacto}</a>
                </div>
                <div>
                  <img src={Reloj_naranjado} alt="Reloj_naranjado" className="pr-2"/>{datosContacto.horario_lu_vi_contacto}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="text-center col-10 offset-1 copyright_footer pt-4 pb-2">{datos.copyright}</div>
        <div className="text-center"><a href="https://pvsagencia.com/" target="_blank" rel="noopener noreferrer" className=" copyright_footer pt-2">Diseñado y desarrollado por PVS AGENCIA</a> </div>
      </div>
    </div>
    </>
  );
}

export default Footer;