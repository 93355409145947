import React from 'react';
import { Col, Container, Media, Row, Spinner } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import usePerfiles from './usePerfiles';
import { API_URL } from '../../constants';
import CarruselClientes from '../../components/carrusel_clientes/CarruselClientes'
import useClientes from '../inicio/useClientes';



function PerfilFirma (props) {

  var dataPerfiles = usePerfiles();
  var dataClientes = useClientes();

  if(dataPerfiles === null || dataClientes === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }
  let datos = dataClientes.data
  let data = dataPerfiles.data[0]
  // console.log(data);
  return (
    <>
      {props.menu} 
      <div className="p-0 m-0">
        {/* escritorio */}
        <div className="perfilfir d-none d-sm-block">
          <Container fluid>
            <Row>
              <Col md={{espan:7, offset:2}} align="center">
                <div className="titulo_perfil_uno pt-5 mt-5" style={{marginLeft:"80px"}}>
                  {data.titulo_uno} 
                </div>
                <div className="titulo_perfil_dos col-8 mt-n3" style={{marginLeft:"195px"}} >
                  {data.titulo_dos}
                </div>
                <div className="titulo_perfil_tres col-10 " style={{marginTop:"-80px", marginLeft:"150px"}}>
                  {data.titulo_tres}
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col>
                <Media>
                  <Media.Body>
                    <div style={{
                      marginLeft: '-30%',
                      padding: '30px',
                    }} className="parrafo_perfil" dangerouslySetInnerHTML={{ __html: data.parrafo_titulo}}/>
                  </Media.Body>
                <div className="p-0" style={{
                  marginRight: '-395px',
                  marginTop: '-44%',
                }}>
                  <img
                    width={917}
                    // height={687}
                    src={API_URL+data.imagen_titulo} 
                    alt="data.imagen_titulo"
                  />
                </div>
                </Media>
              </Col>
            </Row>
          </Container>
          <Container align="center" style={{
            marginTop: '100px',
            paddingLeft: '50px',
            paddingRight: '50px'
          }}>
            <Row>
              <Col lg="6" align="right" className="mision">
                <span className="pr-5">{data.titulo_mision}</span> 
                <hr className="hr_perfilfirma" align="right"/>
                <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "80%"
                }}></div>
              </Col>
              <Col lg="6" align="left" className="vision">
                <span className="pl-5">{data.titulo_vision}</span> 
                <hr className="hr_perfilfirma" align="left" />
                <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "20%"
                }}></div>
              </Col>
            </Row>
            <Row lg="12">
              <Col lg="6">
                <div style={{
                  padding: '5px',
                }} className="parrafo_mision">
                  {data.parrafo_mision}
                </div>
              </Col> 
              <Col lg="6">
                <div style={{
                  padding: '5px',
                }} className="parrafo_vision">
                  {data.parrafo_vision}
                </div>
              </Col>            
            </Row>
          </Container>

          <Container fluid style={{marginTop:"100px"}}>
            <Row>
              <Col align="center" className="titulo_escogernos pb-3">
                {data.titulo_por_que_escogernos}
              </Col>
            </Row>
            <Row>
              <Col align="center" className="sub_titulo_escogernos">
                {data.parrafo_por_que_escogernos}
              </Col>
            </Row>

              <Col className="col-10 offset-1">
                <Row align="center" style={{marginTop:"50px"}}>
                  <Col>
                    <img
                      width={82}
                      height={82}
                      src={API_URL+data.imagen_pe01}
                      alt="data.imagen_pe01"
                    />
                  </Col>
                  <Col>
                    <img
                      width={82}
                      height={82}
                      src={API_URL+data.imagen_pe02}
                      alt="data.imagen_pe02"
                    />
                  </Col>
                  <Col>
                    <img
                      width={82}
                      height={82}
                      src={API_URL+data.imagen_pe03}
                      alt="data.imagen_pe03"
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="col-10 offset-1">
                <Row align="center">
                  <Col>
                    <br/>
                    <div className="titulo_pe01">
                      {data.titulo_pe01}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe01}
                    </div>
                  </Col>
                  <Col>
                    <br/>
                    <div className="titulo_pe01">
                      {data.titulo_pe02}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe02}
                    </div>
                  </Col>
                  <Col>
                    <br/>
                    <div className="titulo_pe01">
                      {data.titulo_pe03}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe03}
                    </div>
                  </Col>
                </Row>
              </Col>
            
            <Col className="col-8 offset-2 mb-4">
              <Row align="center" style={{marginTop:"150px"}}>
                <Col>
                  <img
                      width={324}
                      height={324}
                      src={API_URL+data.imagen_sf1} 
                      alt="data.imagen_pe01sds"
                    />              
                </Col>
                <Col>
                  <img
                    width={324}
                    height={324}
                    src={API_URL+data.imagen_sf2} 
                    alt="data.imagen_pesds01"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-8 offset-2">
              <div className="justify-content-md-center">
              <Row>
                <Col md={6}>
                  <Row className="justify-content-md-center">
                    <Col md={4}>
                      <span className="nombre_perfil ">{data.titulo_sf1}</span>             
                    </Col>
                    <Col md={4}>
                    <hr className="hr_izq float-left mt-0 ml-n4"/>
                    <div className="socio_fundador ml-n3">
                      {data.sub_titulo_sf1}
                    </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="justify-content-md-center">
                    <Col md={4}>
                      <span className="nombre_perfil ">{data.titulo_sf2}</span>             
                    </Col>
                    <Col md={4}>
                    <hr className="hr_izq float-left mt-0 ml-n4"/>
                    <div className="socio_fundador ml-n3">
                      {data.sub_titulo_sf2}
                    </div>
                    </Col>
                  </Row>
                </Col>
               
              </Row >
            
            <Row className="justify-content-md-center pt-3 pb-5 mb-5" >
              <Col md={5} className="parrafo_socio_fundador ml-n4">
                {data.parrafo_sf1}
              </Col>
              
              <Col md={{span:5, offset:1}} className="parrafo_socio_fundador">
                {data.parrafo_sf2}
              </Col>
            </Row>

            <Row className="justify-content-md-center  mb-5">
              <Col md={3} className="clientes text-center">
                {data.titulo_clientes}
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
              </Col>
            </Row>
            </div>
            </Col>
          </Container>
        </div>

        {/* movil */}
        <div className="perfilfir d-block d-sm-none" 
          style={{
            overflowX: "hidden"
          }}>
          <Container fluid>
            <Row>
              <Col md={{espan:7, offset:2}} align="center">
                <div className="titulo_perfil_uno_movil " style={{marginLeft:"10px"}}>
                  {data.titulo_uno} 
                </div>
                <div className="titulo_perfil_dos_movil col-8" style={{marginLeft:"60px", marginTop:"-35px" }} >
                  {data.titulo_dos}
                </div>
                <div className="titulo_perfil_tres_movil col-10 " style={{marginLeft:"20px", marginTop:"-20px"}}>
                  {data.titulo_tres}
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col>
                <Media>
                  
                <div className="p-0" style={{
                  marginLeft: '195px',
                  marginTop: '-42%',
                }}>
                  <img
                    width={250}
                    // height={687}
                    src={API_URL+data.imagen_titulo} 
                    alt="data.imagen_titulo"
                  />
                </div>
                </Media>
                <div style={{
                  // marginLeft: '-30%',
                  padding: '30px',
                }} className="parrafo_perfil" dangerouslySetInnerHTML={{ __html: data.parrafo_titulo}}/>
              </Col>
            </Row>
          </Container>

          <Container align="center" style={{
            // marginTop: '50px',
            // paddingLeft: '50px',
            // paddingRight: '50px'
          }}>
            <Row>
              <Col xs="12" className="mision_movil" style={{marginTop:"50px"}}>
                <span className="">{data.titulo_mision}</span> 
                <hr className="hr_perfilfirma"/>
                <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
                <div style={{
                  padding: '30px',
                }} className="parrafo_perfil">
                  {data.parrafo_mision}
                </div>
              </Col>
              <Col xs="12"  className="vision_movil" style={{marginTop:"50px"}}>
                <span className="">{data.titulo_vision}</span> 
                <hr className="hr_perfilfirma"/>
                <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
                <div style={{
                  padding: '30px',
                }} className="parrafo_perfil">
                  {data.parrafo_vision}
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid style={{marginTop:"70px"}}>
            <Row>
              <Col align="center" className="titulo_escogernos_movil pb-3">
                {data.titulo_por_que_escogernos}
              </Col>
            </Row>
            <Row>
              <Col align="center" className="sub_titulo_escogernos">
                {data.parrafo_por_que_escogernos}
              </Col>
            </Row>
              <Col className="col-12" style={{marginTop:"80px"}}>
                <Row align="center">
                  <Col xs={12} className="pb-5 pb-md-0">
                    <img
                      className="pb-2"
                      width={82}
                      height={87}
                      src={API_URL+data.imagen_pe01}
                      alt="data.imagen_pe01"
                    />
                    <div className="titulo_pe01_movil">
                      {data.titulo_pe01}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" className="pb-4" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe01}
                    </div>
                  </Col>
                  <Col xs={12} className="pb-5 pb-md-0">
                    <img
                      className="pb-2"
                      width={82}
                      height={87}
                      src={API_URL+data.imagen_pe02}
                      alt="data.imagen_pe02"
                    />
                    <div className="titulo_pe01_movil">
                      {data.titulo_pe02}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" className="pb-4" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe02}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <img
                      className="pb-2"
                      width={82}
                      height={87}
                      src={API_URL+data.imagen_pe03}
                      alt="data.imagen_pe03"
                    />
                    <div className="titulo_pe01_movil">
                      {data.titulo_pe03}
                    </div>
                    <hr className="hr_n"/>
                    <div align="left" className="pb-4" style={{
                      fontSize: '14px',
                      color: '#808080'
                    }}>
                      {data.parrafo_pe03}
                    </div>
                  </Col>
                </Row>
              </Col>
            
            <Col xs={12} className="mb-4">
              <Row align="center" style={{marginTop:"80px"}}>
                <Col xs={12}>
                  <img
                      className="pb-2 mb-3"
                      width={165}
                      height={175}
                      src={API_URL+data.imagen_sf1} 
                      alt="data.imagen_pe01sds"
                    />  
                    <Col xs={12} className="pb-4">
                      <Row >
                        <Col xs={7}>
                          <span className="nombre_perfil_movil ">{data.titulo_sf1}</span>             
                          <hr className="hr_izq float-right mt-0"/>
                        </Col>
                        <Col xs={5} >
                        <div className="socio_fundador_movil pl-1 ml-n5">
                          {data.sub_titulo_sf1}
                        </div>
                        </Col>
                        <Col md={12} className="parrafo_socio_fundador pt-2 pb-4">
                          {data.parrafo_sf1}
                        </Col>
                      </Row>
                    </Col>
                </Col>
                <Col xs={12}>
                  <img
                    className="pb-2 mb-3"
                    width={165}
                    height={175}
                    src={API_URL+data.imagen_sf2} 
                    alt="data.imagen_pesds01"
                  />
                  <Col xs={12} className="pb-4">
                      <Row >
                        <Col xs={7}>
                          <span className="nombre_perfil_movil ">{data.titulo_sf2}</span>             
                          <hr className="hr_izq float-right mt-0"/>
                        </Col>
                        <Col xs={5} >
                        <div className="socio_fundador_movil pl-1 ml-n5">
                          {data.sub_titulo_sf2}
                        </div>
                        </Col>
                        <Col md={12} className="parrafo_socio_fundador pt-2 pb-5">
                          {data.parrafo_sf2}
                        </Col>
                      </Row>
                    </Col>
                </Col>
              </Row>
            </Col>
            <Col className="col-8 offset-2">
              <div className="justify-content-md-center">
                <Row className="justify-content-md-center  mb-5">
                  <Col md={3} className="clientes text-center">
                    {data.titulo_clientes}
                  <hr className="hr_n"/>
                  <div style={{
                      borderRadius:"50%",
                      border:"3px solid #F58465",
                      width:"2px",
                      marginTop:"-20px",
                      marginLeft: "50%"
                    }}></div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Container>
        </div>

          <div className="mb-5 pb-5">
            <CarruselClientes data = {datos}/>
          </div>
      </div>
      <Footer/>
    </>
  );
}

export default PerfilFirma;