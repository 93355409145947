import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import MenuEscritorio from "./components/menu/MenuEscritorio";
import Sectores from "./pages/sectores/Sectores";
import Abogados from "./pages/abogados/Abogados";
import Inicio from './pages/inicio/Inicio';
import PerfilFirma from "./pages/perfil_firma/PerfilFirma";
import './styles/sass/layout.scss';
import AreasDePractica from "./pages/areas_practicas/AreasDePractica";
import Noticias from "./pages/noticias/Noticias";
import Contacto from "./pages/contacto/Contacto";
import useMenus from './useMenus';
import Noticia from './pages/noticia/noticia';



function App() {
  var menuAxio = useMenus();
  if(menuAxio === null){
    return ""
  }

  const menu = <MenuEscritorio 
    menuData={menuAxio.data}
  />

  return (
    <>
    <Switch>
      <Route 
        exact 
        path="/"
        render={() => (<Redirect to={"/inicio"}/>)} 
      />
      <Route 
        exact 
        path={"/inicio"} 
        render={(props) => (<Inicio 
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path={"/perfilFirma"} 
        render={(props) => (<PerfilFirma 
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path={"/abogados"} 
        render={(props) => (<Abogados
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path={"/sectores"} 
        render={(props) => (<Sectores
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path={"/areasDePractia"} 
        render={(props) => (<AreasDePractica
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path={"/noticias"} 
        render={(props) => (<Noticias
          menu={menu}
          />)} 
      />
      <Route 
        exact 
        path="/noticia/:titulo"
        render={(props) => (<Noticia 
          menu={menu}
          />)}
      />
      <Route 
        exact 
        path={"/contacto"} 
        render={(props) => (<Contacto
          menu={menu}
          />)} 
      />
      
    </Switch>
    </>
  );
}

export default App;
