import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import hamburguesa from '../../img/menu/hamburguesa.svg'
import flechaLeft from '../../img/menu/flechaLeft.svg'
import { Accordion, Card } from 'react-bootstrap';

const useStyles = makeStyles({
  list: {
    width: 260,
  },
  fullList: {
    width: 'auto',
  },
});

const MenuMovil = props => {

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory()

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // dentro del menu
  const list = (anchor) => (
    <div className="menu-movil-styles" style={{height:"100%", backgroundColor:"#1C2D56"}}>
    {/* ancho */}
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })} 
      >
        {/* boton cerrar */}
        <img className="m-4 pl-2 pr-2" src={flechaLeft} alt="flechaLeft" onClick={toggleDrawer(anchor, false)} style={{cursor:"pointer"}}/>

        {/* menu */}
        <Accordion>
          <Card style={{backgroundColor:"#1C2D56", color:"white"}}>
            <List className="">
              <Link to={'/inicio'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("inicio") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[0].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/perfilFirma'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("perfilFirma") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[1].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/abogados'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("abogados") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[2].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/sectores'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("sectores") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[3].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/areasDePractia'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("areasDePractia") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[4].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/noticias'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("noticias") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[5].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
              <Link to={'/contacto'} style={{textDecoration: "none"}}>
                <Divider/>
                  <ListItem button className={`pl-4 pt-3 pb-3 text-white ${history.location.pathname.includes("contacto") ? "menuactivo" : ""} `}>
                      <ListItemText>{props.menuData[6].nombre_menu}</ListItemText>
                  </ListItem>
                <Divider/>
              </Link>
            </List>
          </Card>
        </Accordion>
      </div>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} className="d-block d-lg-none">
            <img src={hamburguesa} alt="hamburguesa"/>
          </IconButton>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
export default MenuMovil