import axios from "axios";
import { API_URL } from "../../constants";

export const getPublicacion = props => {
    let caracter = / /g;
    return dispatch => {
        axios.get(API_URL + `api/publicacions/ver/${props.replace(caracter, '%20')}`)
        .then(response => {            
            dispatch({
                type: "GET_PUBLICACION",
                publicacion: response.data
            });
        }).catch(error => {
            const err = Object.assign({}, error);
            console.log(error);
            console.log(err);
        });
        
    }
}