import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getPageContactos } from '../../redux/actions/getPageContactos';

export default function usePageContactos (props) {

    const pagecontactos = useSelector(state => state.pagecontactos);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPageContactos())
    },[])

    return pagecontactos;
}