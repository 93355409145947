import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getContactos } from '../../redux/actions/getContactos';

export default function useContactos (props) {

    const contactos = useSelector(state => state.contactos);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContactos())
    },[])

    return contactos;
}