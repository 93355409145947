import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
// import CuerpoNoticia from '../../componentes/noticia/CuerpoNoticia';
// import CaruselNoticia from './CaruselNoticia';
import usePublicacion from './usePublicacion';
import moment from 'moment';
import 'moment/locale/es-mx';
import { API_URL } from '../../constants';
import ReactPlayer from 'react-player'


const Noticia = props => {
  const history = useHistory()
  // var ArticuloActual = history.location.pathname.substr(13)
  var ArticuloActual = history.location.pathname.substr(9)
  const dataPublicacion = usePublicacion(ArticuloActual)

  if(dataPublicacion === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }
  let data = dataPublicacion.data
  // console.log(data);
  return (
    <>
    {props.menu}

      <Container fluid className="noticias-style pt-md-5 mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3}}>
            <div className=" justify-content-md-center pb-md-5 row">
              <div className="titulo_noticias text-left col-9">
                {data.nombre_publicacion}
              </div>
              {/* escritorio */}
              <div className="volver_noticias col-3 pt-3 text-right d-none d-sm-block">
                <Link to={'/noticias/'}>&lt; Volver a Noticias</Link>  
              </div>
              {/* movil */}
              <div className="volver_noticias col-3 pt-3 text-right d-block d-sm-none">
                <Link to={'/noticias/'}>&lt; Volver</Link>  
              </div>
              <div className="fecha_noticia col-12">
              {moment(data.fecha_publicacion).format('DD [de] MMMM [de] YYYY')} - {data.categoria.nombre_categoria}
              </div>
            </div>
              <div className="pb-md-5 text-center pt-5">
                {data.categoria.nombre_categoria === "Videos" ?
                <>
                  <ReactPlayer
                    className="d-none d-sm-block"
                    width="100%"
                    height="450px"
                    controls
                    url={data.imagen_publicacion.startsWith('http') ? data.imagen_publicacion : API_URL+data.imagen_publicacion} 
                  />  
                  <ReactPlayer
                    className="d-block d-sm-none"
                    width="100%"
                    height="150px"
                    controls
                    url={data.imagen_publicacion.startsWith('http') ? data.imagen_publicacion : API_URL+data.imagen_publicacion} 
                  />
                </>
                  :
                  <>
                    <img src={API_URL+data.imagen_publicacion} width="90%" alt="nombre_categoria" className="d-none d-sm-block"/>
                    <img src={API_URL+data.imagen_publicacion} width="100%" alt="nombre_categoria" className="d-block d-sm-none"/>
                  </>
                }
              </div>
              
              <div className="mt-5 mb-5" 
                dangerouslySetInnerHTML={{ __html: data.parrafo }} 
              >
              </div>
            <div className="text-center mb-5 pb-5">
            </div>
          </Col>
        </Row>
      </Container>
    <Footer/>
    </>
  )
}

export default Noticia