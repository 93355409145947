import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getCarrucelInicio } from '../../redux/actions/getCarrucelInicio';

export default function useCarrucelInicio (props) {

    const carrucelinicio = useSelector(state => state.carrucelinicio);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCarrucelInicio())
    },[])

    return carrucelinicio;
}