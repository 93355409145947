import { createStore, applyMiddleware, combineReducers } from "redux";
import menus from './reducers/menus';
import categorias from './reducers/categorias';
import publicacion from './reducers/publicacion';
import publicaciones from './reducers/publicaciones';
import contactos from './reducers/contactos';
import footers from './reducers/footers';
import sectors from './reducers/sectors';
import areas from './reducers/areas';
import perfiles from './reducers/perfiles';
import pagecontactos from './reducers/pagecontactos';
import abogados from './reducers/abogados';
import clientes from './reducers/clientes';
import carrucelinicio from './reducers/carrucelinicio';
import inicio from './reducers/inicio';
import thunk from 'redux-thunk'


const reducers = combineReducers({
    menus,
    categorias,
    publicacion,
    publicaciones,
    contactos,
    footers,
    sectors,
    areas,
    perfiles,
    pagecontactos,
    abogados,
    clientes,
    carrucelinicio,
    inicio,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return reducers(state, action)
}

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store;