import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getFooters } from '../../redux/actions/getFooters';

export default function useFooters (props) {

    const footers = useSelector(state => state.footers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFooters())
    },[])

    return footers;
}