import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getPublicaciones } from '../../redux/actions/getPublicaciones';

export default function usePublicaciones (props) {

    const publicaciones = useSelector(state => state.publicaciones);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPublicaciones())
    },[])

    return publicaciones;
}