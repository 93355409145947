import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getMenus } from './redux/actions/getMenus';

export default function useMenus (props) {

    const menus = useSelector(state => state.menus);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMenus())
    },[])

    return menus;
}