import React from 'react';
import { Spinner } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import { API_URL } from '../../constants';
import useSectors from './useSectors';

function Sectores (props) {
  var dataSectors = useSectors();

  if(dataSectors === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }
  
  let data = dataSectors.data
  // console.log(data);
  return (
    <>
      {props.menu} 
      <div style={{minHeight:"690px"}} className="sectores-style mt-md-5 pt-md-5">
        {data.map(dat =>(
          <div className="container mt-md-5 mb-md-5 pb-5" key={dat.id} >
            <div className="row mb-md-5 justify-content-md-center">
              {/* escritorio */}
              <div className="col-9 offset-1 titulo_sectores d-none d-sm-block">
                {dat.titulo}
              </div>
              {/* movil */}
              <div className="col-2 offset-1 titulo_sectores_movil d-block d-sm-none">
                <img className="float-left pr-1 mt-2 pb-3" width="56px" src={API_URL+dat.imagen} alt="img"/>
              </div>
              <div className="col-9 titulo_sectores_movil d-block d-sm-none" style={{alignSelf: "center"}}>
                <div>{dat.titulo}</div>
              </div>
              {/* escritorio */}
              <div className="col-12 pl-5 ml-5 d-none d-sm-block">
                <img className="float-left pl-2" src={API_URL+dat.imagen} alt="img" style={{position:"absolute", top: "-36px" }}/>
                <hr className="hr_sectores " style={{marginLeft:"112px"}} />
              </div>
              <div className="col-9 offset-1 parrafo_sectores  mt-0 pt-0">
                <div dangerouslySetInnerHTML={{ __html: dat.parrafo }}/>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </>
  );
}

export default Sectores;