import React, { useRef, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { Container, Form, FormControl, Row, Button as Boton, Spinner, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useCategorias from './useCategorias';
import usePublicaciones from './usePublicaciones';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';
import { API_URL } from '../../constants';
import ReactPlayer from 'react-player';
import ArrayPagination from '@vlsergey/react-bootstrap-array-pagination';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const Noticias = props => {
  var dataCategorias = useCategorias();
  var dataPublicaciones = usePublicaciones();
  const classes = useStyles();
  const selectCategoria = useRef(null)
  const selectCategoriaM = useRef(null)
  const [filtroCategoria, setFiltroCategoria] = useState(0)
  const [filtroCategoriaM, setFiltroCategoriaM] = useState(0)
  const [filtroFecha, setfiltroFecha] = useState(0)
  const [busqueda, setBusqueda] = useState("")
  const [filtroBusqueda, setFiltroBusqueda] = useState("")

  if(dataCategorias === null || dataPublicaciones === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }



  let category = dataCategorias.data
  let publicaciones = dataPublicaciones.data
  let publicacionesAnuales = dataPublicaciones.data
  // let publicacionesRecientes = dataPublicaciones.data
  let unique = [...new Set(publicacionesAnuales.sort(({ id: previousID }, { id: currentID }) =>  currentID - previousID).map(item => moment(item.fecha_publicacion).format('YYYY')))];
  // console.log(unique);
  // console.log(filtroCategoria);

  if (Number(filtroCategoria) !== 0) {
    // console.log(publicaciones);
    publicaciones=publicaciones.filter(item => Number(item.categoria_id) === Number(filtroCategoria));
  }else if (Number(filtroFecha)){
    publicaciones=publicaciones.filter(item => Number(moment(item.fecha_publicacion).format('YYYY')) === Number(filtroFecha))
  }

  
  if (Number(filtroCategoriaM) !== 0) {
    // console.log(publicaciones);
    publicaciones=publicaciones.filter(item => Number(item.categoria_id) === Number(filtroCategoriaM));
  }

  publicaciones=publicaciones.filter(item => item.nombre_publicacion.toLowerCase().includes(filtroBusqueda.toLowerCase()))
  publicacionesAnuales.filter(item => item.fecha_publicacion)

  ArrayPagination.defaultProps = {
    defaultPage: 0,
    defaultSize: 6,
    pageProps: {},
    sizeProps: {
      options: [6, 12, 24, 36, 100, 200]
    }
  };
  return (
    <>
    {props.menu}
      <div className="noticias-style" style={{minHeight:"900px"}}>
        {/* <div style={{
          backgroundImage: `url(${fotomenu})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          height: "260px",
          width:"100%",
          top:"0px",
          zIndex:"-1"
        }}></div> */}

        <Container fluid>
          <Row>
            <Col md={{span: 2, offset: 4}} className="text-center mb-md-5">
              <div className="titulo_noticias pt-md-5 mt-3 ">NOTICIAS</div>
              <hr className="hr_n"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "50%"
                }}></div>
            </Col>
          </Row>
        </Container>

        {/* tergetas */}
        <Container fluid>
          <Row className="justify-content-md-center pt-5 pb-5">
            <Col xs={{span:12}} md={{span: 8, offset: 1}}>
              <Row>
              <ArrayPagination 
              defaultSize={6}
              defaultPage={0}
              items={publicaciones.filter(item => item.nombre_publicacion.toLowerCase().includes(filtroBusqueda.toLowerCase()))}>
                { ({ components, pageItems }) => 
                  <>
                    {pageItems
                      .sort(({ id: previousID }, { id: currentID }) =>  currentID - previousID)
                      .map(publicacion => (
                        <Col xs={6} md={4} key={publicacion.id} className="pb-5">
                          <Link to={`/noticia/${publicacion.nombre_publicacion}`} style={{textDecoration:"none"}}>
                            {/* escritorio */}
                            <div className="d-none d-sm-block">
                              <Card className={classes.root} >
                                <CardActionArea>
                                {publicacion.categoria_nombre === "Videos" ?
                                  <ReactPlayer
                                    height="295px" 
                                    width="100%"
                                    controls
                                    url={publicacion.imagen_publicacion.startsWith('http') ? publicacion.imagen_publicacion : API_URL+publicacion.imagen_publicacion} 
                                  />
                                :
                                  <CardMedia
                                    className={classes.media}
                                    image={API_URL+publicacion.imagen_publicacion}
                                    title={publicacion.nombre_publicacion}
                                    style={{height: "300px"}}
                                  />
                                }
                                  
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" className="titulo_noticia text-truncate">
                                      {publicacion.nombre_publicacion}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className="fecha ">
                                      {moment(publicacion.fecha_publicacion).format('DD [de] MMMM [de] YYYY')} - {publicacion.categoria_nombre}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className="parrafo_noticia">
                                      <div dangerouslySetInnerHTML={{ __html: publicacion.parrafo.substr(0,120)+"..." }}/>
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                                <CardActions>
                                  <Button size="small" color="primary">
                                    <div className="nombre_vermas" style={{textTransform: "lowercase"}}><span style={{textTransform: "uppercase"}}>V</span>er más</div>
                                  </Button>
                                </CardActions>
                              </Card>
                            </div>

                            {/* movil */}
                            <div className="d-block d-sm-none">
                              <Card className={classes.root} >
                                <CardActionArea>
                                {publicacion.categoria_nombre === "Videos" ?
                                  <ReactPlayer
                                    height="150px" 
                                    width="100%"
                                    controls
                                    url={publicacion.imagen_publicacion.startsWith('http') ? publicacion.imagen_publicacion : API_URL+publicacion.imagen_publicacion} 
                                  />
                                :
                                  <CardMedia
                                    className={classes.media}
                                    image={API_URL+publicacion.imagen_publicacion}
                                    title={publicacion.nombre_publicacion}
                                    style={{height: "150px"}}
                                  />
                                }
                                  
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" className="titulo_noticia text-truncate">
                                      {publicacion.nombre_publicacion}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className="fecha ">
                                      {moment(publicacion.fecha_publicacion).format('DD [de] MMMM [de] YYYY')} - {publicacion.categoria_nombre}
                                    </Typography>
                                    {/* <Typography variant="body2" color="textSecondary" component="p" className="parrafo_noticia">
                                      <div dangerouslySetInnerHTML={{ __html: publicacion.parrafo.substr(0,120)+"..." }}/>
                                    </Typography> */}
                                  </CardContent>
                                </CardActionArea>
                                <CardActions>
                                  <Button size="small" color="primary">
                                    <div className="nombre_vermas" style={{textTransform: "lowercase"}}><span style={{textTransform: "uppercase"}}>V</span>er más</div>
                                  </Button>
                                </CardActions>
                              </Card>
                            </div>
                          </Link> 
                        </Col>
                      ))}
                      <div className="col-md-4 offset-md-5" style={{marginTop:"50px",}}>
                      {components}
                    </div>
                  </>
                }
              </ArrayPagination>
              </Row>
            </Col>

            {/* panel lateral */}
              {/* escritorio */}
              <Col md={3} className="d-none d-sm-block">
                <div className="col-md-12"> 
                  <Form inline >
                    <FormControl 
                    placeholder="Buscar"
                    type="search"
                    onChange={e => {
                      setBusqueda(e.target.value)
                      if (e.target.value === "") {
                        setFiltroBusqueda("")
                        // console.log("limpiado")
                      }
                    }}
                    style={{
                      height:"46px"
                    }}/>
                    <Boton className="btn btn-primary bouton-image monBouton" 
                      onClick={() => {
                        setFiltroBusqueda(busqueda)
                      }}
                      style={{
                        marginLeft:"2px",
                        height:"45px",
                        paddingLeft: "5px",
                        paddingRight: "9px"
                      }}>
                    </Boton>
                  </Form>

                  <Form.Control 
                    className="mt-3"
                    style={{ width:"250px", height:"46px" }} 
                    ref={selectCategoria} 
                    onChange={()=>{
                      setFiltroCategoria(selectCategoria.current.value)
                    }}
                    as="select">
                      <option value="0">Categoría</option>
                        {category.map(categoria => (
                          <option key={categoria.id} value={categoria.id}>{categoria.nombre_categoria}</option>
                        ))}
                  </Form.Control>
                  <div style={{width:"250px"}}>
                    {/* <div className="entradas_recientes pt-3">
                      Entradas Recientes
                      <hr className="hr_noticias mt-0 ml-0"/>
                    </div>
                    {publicacionesRecientes
                    .sort(({ id: previousID }, { id: currentID }) =>  currentID - previousID)
                    .slice(0, 4)
                    .map(publicacion => (
                      <div className="entrada_rec">
                        {publicacion.nombre_publicacion}
                        <hr className="hr_noti ml-0"/>
                      </div>
                    ))} */}

                    <div className="entradas_recientes pt-4 archivo_noticia">
                      Archivos
                      <hr className="hr_noticias mt-0 ml-0"/>
                    </div>
                    <div className="entrada_rec" 
                      onClick={() => {
                        setfiltroFecha(0)
                      }}>
                        Todos
                        <hr className="hr_noti ml-0"/>
                      </div>
                    {unique
                    .slice(0, 4)
                    .map(publicacion => (
                      <div className="entrada_rec" 
                      key={publicacion.id}
                      onClick={() => {
                        setfiltroFecha(publicacion)
                      }}>
                        {publicacion}
                        
                        <hr className="hr_noti ml-0"/>
                      </div>
                    ))}
                  </div>

                </div>
              </Col>
            

              {/* movil */}
              <Col xs={12} md={3} className="d-block d-sm-none col order-first text-center">
                <div className="col-md-12 pb-5 text-center"> 
                  <Form inline>
                    <FormControl                    
                    placeholder="Buscar"
                    type="search"
                    onChange={e => {
                      setBusqueda(e.target.value)
                      if (e.target.value === "") {
                        setFiltroBusqueda("")
                        // console.log("limpiado")
                      }
                    }}
                    style={{
                      height:"46px",
                      width:"84%"
                    }}/>
                    <Boton className="btn btn-primary bouton-image monBouton" 
                      onClick={() => {
                        setFiltroBusqueda(busqueda)
                      }}
                      style={{
                        marginLeft:"4px",
                        height:"45px",
                        paddingLeft: "5px",
                        paddingRight: "9px"

                      }}>
                    </Boton>
                  </Form>

                  <Form.Control 
                    className="mt-3"
                    style={{ width:"98%", height:"46px" }} 
                    ref={selectCategoriaM} 
                    onChange={()=>{
                      setFiltroCategoriaM(selectCategoriaM.current.value)
                    }}
                    as="select">
                      <option value="0">Categoría</option>
                        {category.map(categoria => (
                          <option key={categoria.id} value={categoria.id}>{categoria.nombre_categoria}</option>
                        ))}
                  </Form.Control>
                  
                </div>
              </Col>
          </Row>
        </Container> 
      </div>
    <Footer/>
    </>
  )
}

export default Noticias