import axios from "axios";
import { API_URL } from '../../constants'

export const getPublicaciones = () => {
    return dispatch => {
        axios.get(API_URL + 'api/categorias')
        .then(response => {            
            dispatch({
                type: "GET_CATEGORIAS",
                categorias: response.data
            });
            axios.get(API_URL + 'api/publicacions')
            .then(response2 => { 
                    let publicaciones = response2.data.data.map(publicacion => {
                        let categoria = response.data.data.find(c => c.id == publicacion.categoria_id)
                        publicacion.categoria_nombre = categoria.nombre_categoria
                        return publicacion
                    })
                dispatch({
                    type: "GET_PUBLICACIONES",
                    publicaciones: { data: publicaciones }
                });
            }).catch(error => {
                const err = Object.assign({}, error);
                console.log(error);
                console.log(err);
            });
        }).catch(error => {
            const err = Object.assign({}, error);
            console.log(error);
            console.log(err);
        });
    }
}