import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getSectors } from '../../redux/actions/getSectors';

export default function useSectors (props) {

    const sectors = useSelector(state => state.sectors);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSectors())
    },[])

    return sectors;
}