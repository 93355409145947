import axios from "axios";
import { API_URL } from "../../constants";

export const getAbogados = () => {
    return dispatch => {
        axios.get(API_URL + 'api/abogados')
        .then(response => {            
            dispatch({
                type: "GET_ABOGADOS",
                abogados: response.data
            });
        }).catch(error => {
            const err = Object.assign({}, error);
            console.log(error);
            console.log(err);
        });
        
    }
}