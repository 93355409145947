import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getInicio } from '../../redux/actions/getInicio';

export default function useInicio (props) {

    const inicio = useSelector(state => state.inicio);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInicio())
    },[])

    return inicio;
}