import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAbogados } from '../../redux/actions/getAbogados';

export default function useAbogados (props) {

    const abogados = useSelector(state => state.abogados);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAbogados())
    },[])

    return abogados;
}