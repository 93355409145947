import React, { Component } from "react";
import Slider from "react-slick";
import { API_URL } from "../../constants";
  
export default class CarruselClientes extends Component { 
  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      slidesToScroll: 1,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2
          }
        }
      ]
    };

    // console.log(this.props.data);
    let datos = this.props.data
    return (
      <div className="">
        <div className="galeria-producto" style={{ 
          textAlign: "-webkit-center"
          }}>
          <div className="col-md-8">
            <Slider {...settings}>
              {datos.map(dato =>(
                <img key={dato.id} src={API_URL+dato.logo_cliente} alt="logo_cliente"/>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}