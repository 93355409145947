import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getCategorias } from '../../redux/actions/getCategorias';

export default function useCategorias (props) {

    const categorias = useSelector(state => state.categorias);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategorias())
    },[])

    return categorias;
}