import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getPerfiles } from '../../redux/actions/getPerfiles';

export default function usePerfiles (props) {

    const perfiles = useSelector(state => state.perfiles);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPerfiles())
    },[])

    return perfiles;
}