import React from 'react';
import { Spinner } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import { API_URL } from '../../constants';
import useAreas from './useAreas';

function AreasDePractica (props) {
  var dataAreas = useAreas();

  if(dataAreas === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }
  
  let data = dataAreas.data
  // console.log(data);
  return (
    <>
      {props.menu} 
      <div style={{minHeight:"690px"}} className="areapractica-style mt-md-5 pt-md-5">
        {data.map(dat =>(
          <div className="container mt-md-5 mb-md-5 pb-md-5" key={dat.id} >
            <div className="row mb-5">
              {/* escritorio */}
              <div className="col-10 offset-1 text-center d-none d-sm-block">
                <img className="pb-2" src={API_URL+dat.imagen} alt="img" style={{position:"relative" }}/>
              </div>
              {/* movil */}
              <div className="col-2 offset-1 titulo_sectores_movil d-block d-sm-none">
                <img className="float-left pr-3 mt-2 " width="70px" src={API_URL+dat.imagen} alt="img"/>
              </div>
              <div className="col-9 titulo_sectores_movil d-block d-sm-none" style={{alignSelf: "center"}}>
                <div>{dat.titulo}</div>
              </div>
              <div className="col-10 offset-1 titulo_areas text-center pt-3">
                <div className="row justify-content-md-center">
                  <div className="col-12 ">
                    <hr className="hr_areas d-none d-sm-block"/>
                  </div>
                  {/* escritorio */}
                  <div className="col-12 mt-n5 pt-1 ml-0 text-center d-none d-sm-block" >
                    <span style={{background:"white"}} className="px-3">{dat.titulo}</span>
                  </div>
                </div>
              </div>
              <div className="col-10 offset-1 parrafo_areas mt-md-3 pt-0">
                <div dangerouslySetInnerHTML={{ __html: dat.parrafo }}/>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </>
  );
}

export default AreasDePractica;