import axios from "axios";
import { API_URL } from '../../constants'

export const getInicio = () => {
    return dispatch => {
        axios.get(API_URL + 'api/inicios')
        .then(response => {            
            dispatch({
                type: "GET_INICIO",
                inicio: response.data
            });
        }).catch(error => {
            const err = Object.assign({}, error);
            console.log(error);
            console.log(err);
        });
        
    }
}