import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getClientes } from '../../redux/actions/getClientes';

export default function useClientes (props) {

    const clientes = useSelector(state => state.clientes);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientes())
    },[])

    return clientes;
}