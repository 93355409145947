import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { API_URL } from "../../constants";


export default class CaruselInicio extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 10000,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect:false,
    };
    var carusels = this.props.data

    
    // console.log(carusels);
    return (
      <div className="home-styles">
        <Container fluid>
          <Row >
            <Col style={{
              padding: "0px",
              margin: "0px",
            }}>
              <Slider {...settings} >
                {carusels.map( carusel =>(
                    <div key={carusel.id}>
                        {/* escritorio */}
                        { <div 
                            className="video-responsive d-none d-sm-block"
                            style={{
                              marginTop: "0px"
                            }}
                          >
                            <video width="100%" controls muted autoPlay loop>
                              <source src={API_URL+carusel.video_inicio}/>
                              Su navegador no soporta la etiqueta VIDEO.
                            </video>
                          </div> }

                        
                        {/* movil */}
                        {<div 
                            className="video-responsive d-block d-sm-none"
                            style={{
                              marginTop: "0px"
                            }}
                          >
                            <video width="100%" controls muted autoPlay loop>
                              <source src={API_URL+carusel.video_inicio}/>
                              Su navegador no soporta la etiqueta VIDEO.
                            </video>
                          </div>}
                        
                    </div>
                  ))}
              </Slider>
              </Col>
          </Row>
        </Container>
      </div>
      
    );
  }
}