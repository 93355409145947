import React from 'react';
import { Col, Container, Media, Row, Spinner } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import useAbogados from './useAbogados';
import { API_URL } from '../../constants';


function Abogados (props) {
  var dataAbogados = useAbogados();

  if(dataAbogados === null){
    return <div style={{
      backgroundColor:"white",
      zIndex:"1",
      minHeight: "800px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spinner 
        animation="border" 
        variant="primary" 
        style={
          {width:"100px", 
          height:"100px"
        }}
      />
    </div>
  }

  let data = dataAbogados.data
  // console.log(data);

  return (
    <>
      {props.menu} 
      <div style={{minHeight:"690px"}} className="abogados-style">
        <Container fluid>
          <Row align="center" className="justify-content-md-center">
            <Col md={2} className="mt-md-5 pt-md-5 pb-md-5 mb-5">
              <span className="titulo_abogados">EQUIPO HUMANO</span>
              <hr className="hr_eq"/>
              <div style={{
                  borderRadius:"50%",
                  border:"3px solid #F58465",
                  width:"2px",
                  marginTop:"-20px",
                  marginLeft: "0%"
                }}></div>
            </Col>
          </Row>
          {/* escritorio */}
          <div className="d-none d-sm-block">
            <Row className="pb-5 mb-5">
              <Col md={{span:4, offset:2}} >
                <Media>
                  <img
                    width={161}
                    height={161}
                    className="align-self-center mr-3"
                    src={API_URL+data[0].imagen}
                    alt="data[0].imagen"
                  />
                  <Media.Body>
                    <div className="titulo_nombre">
                      {data[0].nombre}
                    </div>
                    <div className="sub_titulo_nombre">
                      {data[0].cargo}
                    </div>
                    <hr className="hr_sd"/>
                    <div className="parrafo_abogado">
                      {data[0].parrafo}
                    </div>
                  </Media.Body>
                </Media>
              </Col>
              <Col md={{span:4, offset:1}}>
                <Media>
                  <img
                    width={164}
                    height={164}
                    className="align-self-center mr-3"
                    src={API_URL+data[1].imagen}
                    alt="data[1].imagen"
                  />
                  <Media.Body>
                    <div className="titulo_nombre">
                    {data[1].nombre}
                    </div>
                    <div className="sub_titulo_nombre">
                    {data[1].cargo}
                    </div>
                    <hr className="hr_sd"/>
                    <div className="parrafo_abogado">
                    {data[1].parrafo}  
                    </div>
                  </Media.Body>
                </Media>       
              </Col>
            </Row>
          </div>
          {/* movil */}
          <div className="d-block d-sm-none">
            <Row className="mb-5">
              <Col align="center" xs={{span:10, offset:1}}>
                <img
                  width={100}
                  height={125}
                  className="align-self-center mr-3 pb-4"
                  src={API_URL+data[0].imagen}
                  alt="data[0].imagen"
                />
                <div className="titulo_nombre_movil pb-1">
                  {data[0].nombre}
                </div>
                <div className="sub_titulo_nombre_movil pb-2">
                  {data[0].cargo}
                </div>
                <div className="parrafo_abogado_movil mb-5">
                  {data[0].parrafo}
                </div>
              </Col>
              <Col align="center" xs={{span:10, offset:1}}>
                <img
                  width={100}
                  height={125}
                  className="align-self-center mr-3 pb-4"
                  src={API_URL+data[1].imagen}
                  alt="data[1].imagen"
                />
                <div className="titulo_nombre_movil pb-1">
                  {data[1].nombre}
                </div>
                <div className="sub_titulo_nombre_movil pb-2">
                  {data[1].cargo}
                </div>
                <div className="parrafo_abogado_movil pb-4">
                  {data[1].parrafo}
                </div>
              </Col>
            </Row>
          </div>

          {/* escritorio */}
          <div className="d-none d-sm-block">
          <Row className="align-self-center mr-md-3 pb-5 mb-5" align="center">
            <Col>
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[2].imagen}
                alt="data[2].imagen"
              />
              <div className="titulo_nombre_asociados">{data[2].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[2].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[2].parrafo} 
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[3].imagen}
                alt="data[3].imagen"
              />
              <div className="titulo_nombre_asociados">{data[3].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[3].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[3].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[4].imagen}
                alt="data[4].imagen"
              />
              <div className="titulo_nombre_asociados">{data[4].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[4].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[4].parrafo} 
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[5].imagen}
                alt="data[5].imagen"
              />
              <div className="titulo_nombre_asociados">{data[5].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[5].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[5].parrafo}
              </div>
            </Col>
          </Row>

          <Row className="align-self-center mr-md-3 pb-5 mb-5" align="center">
            <Col>
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[6].imagen}
                alt="data[6].imagen"
              />
              <div className="titulo_nombre_asociados">{data[6].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[6].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[6].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[7].imagen}
                alt="data[7].imagen"
              />
              <div className="titulo_nombre_asociados">{data[7].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[7].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[7].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[8].imagen}
                alt="data[8].imagen"
              />
              <div className="titulo_nombre_asociados">{data[8].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[8].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[8].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[9].imagen}
                alt="data[9].imagen"
              />
              <div className="titulo_nombre_asociados">{data[9].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[9].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[9].parrafo}
              </div>
            </Col>
          </Row>

          <Row className="align-self-center mr-md-3 pb-5 mb-5" align="center">
            <Col>
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[10].imagen}
                alt="data[10].imagen"
              />
              <div className="titulo_nombre_asociados">{data[10].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[10].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[10].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[11].imagen}
                alt="data[11].imagen"
              />
              <div className="titulo_nombre_asociados">{data[11].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[11].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[11].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[12].imagen}
                alt="data[12].imagen"
              />
              <div className="titulo_nombre_asociados">{data[12].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[12].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[12].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-5 pt-sm-0">
              <img
                width={164}
                height={164}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[13].imagen}
                alt="data[13].imagen"
              />
              <div className="titulo_nombre_asociados">{data[13].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[13].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[13].parrafo}
              </div>
            </Col>
          </Row>
          </div>

          {/* movil */}
          <div className="d-block d-sm-none">
          <Row className="align-self-center mr-md-3 pb-3 mb-5" align="center">
            <Col>
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[2].imagen}
                alt="data[2].imagen"
              />
              <div className="titulo_nombre_asociados">{data[2].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[2].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[2].parrafo} 
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[3].imagen}
                alt="data[3].imagen"
              />
              <div className="titulo_nombre_asociados">{data[3].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[3].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[3].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[4].imagen}
                alt="data[4].imagen"
              />
              <div className="titulo_nombre_asociados">{data[4].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[4].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[4].parrafo} 
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[5].imagen}
                alt="data[5].imagen"
              />
              <div className="titulo_nombre_asociados">{data[5].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[5].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[5].parrafo}
              </div>
            </Col>
          </Row>

          <Row className="align-self-center mr-md-3 pb-3 mb-5" align="center">
            <Col>
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[6].imagen}
                alt="data[6].imagen"
              />
              <div className="titulo_nombre_asociados">{data[6].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[6].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[6].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[7].imagen}
                alt="data[7].imagen"
              />
              <div className="titulo_nombre_asociados">{data[7].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[7].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[7].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[8].imagen}
                alt="data[8].imagen"
              />
              <div className="titulo_nombre_asociados">{data[8].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[8].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[8].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[9].imagen}
                alt="data[9].imagen"
              />
              <div className="titulo_nombre_asociados">{data[9].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[9].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
              {data[9].parrafo}
              </div>
            </Col>
          </Row>

          <Row className="align-self-center mr-md-3 pb-3 mb-5" align="center">
            <Col>
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[10].imagen}
                alt="data[10].imagen"
              />
              <div className="titulo_nombre_asociados">{data[10].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[10].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[10].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[11].imagen}
                alt="data[11].imagen"
              />
              <div className="titulo_nombre_asociados">{data[11].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[11].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[11].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[12].imagen}
                alt="data[12].imagen"
              />
              <div className="titulo_nombre_asociados">{data[12].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[12].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[12].parrafo}
              </div>
            </Col>
            <Col className="mt-5 mt-sm-0 pt-3 pt-sm-0">
              <img
                width={100}
                height={100}
                className="align-self-center mr-3 mb-4"
                src={API_URL+data[13].imagen}
                alt="data[13].imagen"
              />
              <div className="titulo_nombre_asociados">{data[13].nombre}</div>
              <div className="sub_titulo_nombre_asociados">{data[13].cargo}</div>
              <hr className="hr_n d-none d-sm-block"/>
              <div style={{
                width:'250px'
              }} className="parrafo_abogado_asociados">
                {data[13].parrafo}
              </div>
            </Col>
          </Row>
          </div>
        </Container>        
      </div>
      <Footer/>
    </>
  );
}

export default Abogados;